import React from 'react';
import '../styles/text.less';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';

const PrivacyPolicy = () => (
  <Layout>
    <div>
      <div className="textcontent meta">
        <FormattedHTMLMessage id="privacypolicy.text" />
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
